import React from 'react'
import { Grid, Paper, Tooltip, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useStyles } from './style'
import useResolvePathFromBaseURL from '../../helpers/useResolvePathFromBaseURL'
import { useNavigate } from 'react-router-dom'
import ReButton from '../re-button'

const ResilienceIndicatorCard = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { pathname: indicatorPath } = useResolvePathFromBaseURL('indicator')

  return (
    <Paper elevation={2} className={classes.container}>
      <Grid item xs={12} display='flex' justifyContent='flex-start' alignItems='center' gap={8}>
        <Grid>
          <img src='/img/indicator-card-image.svg' alt='Person looking at indicators' />
        </Grid>
        <Grid display='flex' alignItems='center' justifyContent='center' className='onboarding-self-assessment indicator'>
          <Typography>Resilience Dynamic® Indicator</Typography>
          <Tooltip title='The Resilience Dynamic® Indicator, a light-touch self-assessment of your resilience level.'>
            <InfoOutlinedIcon className={classes.informationIcon} />
          </Tooltip>
        </Grid>
        <ReButton onClick={() => navigate(indicatorPath)} className={classes.startNowButton} color='secondary' variant='contained' name='Start now' />
      </Grid>
    </Paper>
  )
}

export default ResilienceIndicatorCard
