import React, { useMemo, useState } from 'react'
import { useStyles } from '../form/styles'
import { Box, Paper, Typography, LinearProgress } from '@mui/material'
import { CustomOverlay, ReButton } from '../../../../components'
import BasicInfo from './general-info'
import PlanSelection from './plan-selection'
import { useForm, FormProvider } from 'react-hook-form'
import ReviewFormData from './review'

export const PAGE_STEPS = {
  basic_info: 0,
  plan_selection: 1,
  review_form_data: 2
}

const OrgForm = ({ onClick, defaultValues, onSubmit, loadingView, loadingMessage, onCancel, isEdit }) => {
  const classes = useStyles()
  const [step, setStep] = useState(0)

  const methods = useForm({
    mode: 'all',
    defaultValues
  })

  const formTitles = useMemo(() => {
    if (isEdit) return ['Edit Organisation', 'Edit Organisation', 'Review']
    return ['New Organisation', 'New Organisation', 'Review']
  }, [isEdit])

  const goToPage = (step) => {
    setStep(step)
  }

  const manageSubmitButton = onClick = async data => {
    if (step === PAGE_STEPS.review_form_data) {
      const { licenses, ...submitData } = methods.getValues()
      onSubmit(submitData, licenses)
    } else {
      let result
      if (step === PAGE_STEPS.basic_info) {
        result = await methods.trigger(['name', 'orgAdminEmail'])
      } else if (step === PAGE_STEPS.plan_selection) {
        result = await methods.trigger(['licenses'])
      }
      if (result) {
        setStep((currStep) => currStep + 1)
      }
    }
  }

  const managePreviousButton = onClick = data => {
    setStep((currStep) => currStep - 1)
  }

  return (
    <FormProvider {...methods}>
      <form>
        <CustomOverlay
          active={loadingView}
          text={loadingMessage || 'loading...'}
        >
          <div className='form'>
            <Paper className={classes.container}>
              <div className='progress-bar'>
                <LinearProgress
                  variant='determinate'
                  value={step === 0 ? '33.3' : step === 1 ? '66.6' : '100'}
                />
                <Typography color='secondary' fontSize={14}>
                  Step {step === PAGE_STEPS.basic_info ? '1' : step === PAGE_STEPS.plan_selection ? '2' : '3'} of 3
                </Typography>
              </div>
              <div>
                <Typography variant='h4' className={classes.header}>
                  {formTitles[step]}
                </Typography>
              </div>
              <div>
                {step === PAGE_STEPS.basic_info
                  ? <BasicInfo />
                  : step === PAGE_STEPS.plan_selection
                    ? <PlanSelection />
                    : <ReviewFormData goToPage={goToPage} />}
              </div>
              <Box display='flex' flexDirection='row' mt={3.8}>
                <Box>
                  <ReButton
                    name='Cancel'
                    type='button'
                    bgColor='#FFF'
                    color='#465270'
                    btBorderWidth='2px'
                    btBorderColor='#465270'
                    action={onCancel}
                  />
                </Box>
                <Box ml={2.4}>
                  <ReButton
                    name='Previous'
                    type='button'
                    bgColor='#465270'
                    color='#FFFFFF'
                    btBorderColor='#465270'
                    disabled={step === PAGE_STEPS.basic_info && true}
                    action={managePreviousButton}
                  />
                </Box>
                <Box ml={2.4}>
                  <ReButton
                    disabled={Object.keys(methods.formState.errors).length}
                    name={step === formTitles.length - 1 ? 'Finish' : 'Next'}
                    type='button'
                    bgColor='#465270'
                    color='#FFFFFF'
                    btBorderColor='#465270'
                    action={manageSubmitButton}
                  />
                </Box>
              </Box>
            </Paper>
          </div>
        </CustomOverlay>
      </form>
    </FormProvider>
  )
}

export default OrgForm
