import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '100%',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    padding: theme.spacing(3)
  }
}))
