import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '100%',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    padding: theme.spacing(3)
  },
  text: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main
  },
  customToolTip: {
    maxWidth: '300px',
    maxHeight: '120px',
    backgroundColor: 'white',
    border: '1px solid rgba(60, 60, 60, 0.3)',
    padding: '10px',
    fontFamily: 'Lato, Helvetica, sans-serif',
    color: 'rgba(60, 60, 60, 0.75)'
  },
  bar: {
    
  }
}))
