import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, Link, Fade } from '@mui/material'
import { ReButton, TrackerConfiguration } from '../..'
import { useStyles } from './style'
import { settingsSlice } from '../../../reducers'
import useFeatures from '../../../hooks/useFeatures'
import { Box } from '@mui/system'
import { useEventContext } from '../../../contexts/event'

const parseStatus = ({ on, repeat }) => {
  return !on ? 1 : repeat === 'daily'
    ? 2 : 3
}

const defaultTrackerMandatoryValues = {
  sleep: false,
  stress: false,
  main: true,
  wellbeing: false,
  contentedness: false
}

const ModalSettings = ({ nextAction, skipAction }) => {
  const classes = useStyles()
  const entries = useFeatures()
  const userSettings = useSelector(settingsSlice.selectors.selectUserSettings)
  const { updateUserSettings } = useEventContext()
  const [trackerSettings, setTrackerSettings] = React.useState(() => {
    if (userSettings) {
      const newTrackerSettings = Object.keys(userSettings.tracker).reduce((acc, key) => {
        acc[key] = parseStatus(userSettings.tracker[key])
        return acc
      }, {})
      return newTrackerSettings
    }
  })

  const handleChanges = (name, value) => {
    updateUserSettings.trigger({ type: 'tracker', name, value })
    setTrackerSettings({ ...trackerSettings, [name]: value })
  }

  const handleNext = () => {
    updateUserSettings.trigger({ type: 'trackerOnBoarding', name: 'trackerInitialSettingsDisabled', value: true })
    nextAction()
  }

  return (
    <Fade in>
      <Box className={classes.root}>
        <Box className={classes.wrapper}>
          <Box>
            <div className={classes.image}>
              <img src='/img/tracker-settings.svg' width='100%' alt='Track resilience modal' />
            </div>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='center' width='50%'>
            <Typography variant='h3' className={classes.title}>
              Resilience Daily Tracker preferences
            </Typography>
            <Typography>
              Set your preferences for how often you wish to track each of the following categories.
              Note: These settings may already be set by your organisation.
            </Typography>
            <Typography fontWeight='bold'>
              Tick the questions that you wish to track
            </Typography>
            <Box width='70%'>
              <TrackerConfiguration
                values={trackerSettings}
                handleChanges={handleChanges}
                isWorking={updateUserSettings.isWorking}
                isMandatoryValues={entries.RESILIENCE_TRACKER.isTrackerMandatory || defaultTrackerMandatoryValues}
              />
            </Box>
          </Box>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between' mt={3} paddingX={3}>
          <Typography className={classes.footerLink} variant='subtitle2' color='textPrimary'>
            <Link className={classes.trackLaterLink} onClick={skipAction}>Skip for now</Link> and remind me later
          </Typography>
          <ReButton name='Next' dataTestId='next_step_modal_btn' action={handleNext} disabled={updateUserSettings.isWorking} />
        </Box>
      </Box>
    </Fade>
  )
}

export default ModalSettings
