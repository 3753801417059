import { Grid, Skeleton, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Bar, BarChart, CartesianGrid, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Empty, ScoreBadge } from '..'
import { resultLabels, resultDescriptions } from '../../helpers/copy'
import useDashboardContext from '../../hooks/useDashboardContext'
import { CustomLabel } from './customLabel'
import { useStyles } from './styles'
import { roundNumber } from '../../helpers/utils'

const chartHeight = 250
export const initialOptions = [
  { key: 'breakdown', name: 'Breakdown', color: '#262D80', position: 0 },
  { key: 'fragmentation', name: 'Fragmentation', color: '#515799', position: 1 },
  { key: 'fragmentation_coping', name: 'Fragmentation to Coping', color: '#8B87BC', position: 2 },
  { key: 'coping', name: 'Coping', color: '#C5C3DE', position: 3 },
  { key: 'break_even', name: 'Middle of Breakeven', color: '#DDC3DE', position: 4 },
  { key: 'bounce_back', name: 'Bounceback', color: '#BB87BC', position: 5 },
  { key: 'start_whoosh', name: 'Start of Whoosh', color: '#DA5398', position: 6 },
  { key: 'whoosh', name: 'Whoosh', color: '#FD5290', position: 7 },
  { key: 'breakthrough', name: 'Breakthrough', color: '#CD1975', position: 8 }
]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}
const CustomToolTip = ({ active, payload, label }) => {
  const classes = useStyles()
  if (active && payload && payload.length) {
    return (
      <div className={classes.customToolTip}>
        <div>
          {label}
        </div>
        <div style={{ color: payload[0].color }}>
          Number of users : {payload[0].value}
        </div>
      </div>
    )
  }

  return null
}

const QuestionnnaireBarChart = ({ data, loading, empty}) => {
  const {isOrgProfile} = useDashboardContext()
  if (loading) {
    return <Skeleton variant='rectangular' width='80%' height={chartHeight} />
  }
  if (empty) {
    return <Empty message={ !!isOrgProfile ? `No data to display. This is because not enough people have provided results for the filtered criteria` : 'There is no data to display.'}/>
  }
  const dataWithZeroes = [...initialOptions.filter(i => !data.find(d => d.key === i.key)).map(i => ({ ...i, value: 0 })), ...data]
  return (
    <ResponsiveContainer width='80%' height={chartHeight + 30}>
      <BarChart
        height={chartHeight + 30}
        data={dataWithZeroes.sort((a, b) => a.position - b.position)}
        margin={{ top: 10, right: 10, left: 10, bottom: 50 }}
      >
        <Tooltip content={<CustomToolTip />} />
        <CartesianGrid vertical={false} />
        <XAxis dataKey='name' interval={0} tick={<CustomLabel />} />
        <YAxis />
        {initialOptions.map(({ key, color }) => (
          <Bar key={key} dataKey={key} stackId='a' barSize={30} fill={color} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

const QuestionnairePieChart = ({ data, loading, empty }) => {
  if (loading) {
    return <Skeleton variant='rectangular' width='20%' height={chartHeight} />
  }
  if (empty) {
    return null
  }
  return (
    <ResponsiveContainer width='20%' height={chartHeight}>
      <PieChart height={chartHeight} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
        <Pie
          data={data}
          cx='50%'
          cy='50%'
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill='#8884d8'
          dataKey='value'
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={initialOptions.find(i => i.key === entry.key).color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

const Scores = ({ loading, empty }) => {
  const { currentData, isOrgProfile } = useDashboardContext()
  const classes = useStyles()

  if (loading) {
    return (
      <Grid display='flex' gap={2} padding={2}>
        <Skeleton variant='rectangular' height='80px' width='25%' />
        <Skeleton variant='rectangular' height='80px' width='25%' />
        <Skeleton variant='rectangular' height='80px' width='25%' />
        <Skeleton variant='rectangular' height='80px' width='25%' />
      </Grid>
    )
  }

  if (empty) {
    return null
  }

  return (
    <Grid display='flex'>
      <ScoreBadge title={resultLabels.orgView.score} description={resultDescriptions.score}>
        <b> {currentData && currentData.score ? roundNumber(currentData.score) : 0} </b> out of 6
      </ScoreBadge>
      <ScoreBadge title={resultLabels.orgView.level} description={resultDescriptions.level}>
        <b> {currentData && currentData.level} </b>
      </ScoreBadge>
      <ScoreBadge title={resultLabels.orgView.range} description={resultDescriptions.range}>
        <b> {currentData && `${currentData.averageLowScore} - ${currentData.averageHighScore}`} </b>
      </ScoreBadge>
      <ScoreBadge title={resultLabels.orgView.variability} description={resultDescriptions.variability}>
        <b className={classes.text}> {currentData && currentData.averageVariance} </b>
      </ScoreBadge>
      {isOrgProfile && currentData.mostFrequentResult &&
        <ScoreBadge title={resultLabels.orgView.mostFrequent} description={resultDescriptions.mostFrequent}>
          <b className={classes.text}> {initialOptions.find(i => i.key === currentData?.mostFrequentResult?.key)?.name || ''} </b>
        </ScoreBadge>}
    </Grid>
  )
}

export const RenderCharts = () => {
  const { viewLoading, isOrgProfile, currentData, dashboardData } = useDashboardContext()

  const modelResultSum = useMemo(() => Object.entries(currentData?.modelResultSum || {}).map(([k, v]) => {
    const option = initialOptions.find(i => i.key === k)
    return ({
      key: k,
      name: option.name,
      value: v,
      [k]: v,
      position: option.position
    })
  }), [currentData])

  const shouldShowEmpty = useMemo(() => {
    if (!Object.keys(currentData?.modelResultSum ?? {}).length) return true
    return false
  }, [currentData])

  const shouldShowScoresEmpty = useMemo(() => {
    if (!currentData) return true
    return false
  }, [currentData])

  return (
    <Grid>
      {(isOrgProfile || shouldShowScoresEmpty) &&
        <Grid width='100%' height='100%' display='flex' gap={2} padding={0}>
          <QuestionnnaireBarChart data={modelResultSum} loading={viewLoading} empty={shouldShowEmpty}/>
          <QuestionnairePieChart data={modelResultSum} loading={viewLoading} empty={shouldShowEmpty} />
        </Grid>}
      <Scores loading={viewLoading} empty={shouldShowScoresEmpty} />
      {dashboardData?.evaluationTotalUsers && !viewLoading
        ? (
          <Grid display='flex' justifyContent='flex-end' paddingRight={1}>
            <Typography>{dashboardData?.evaluationTotalUsers} results for the filtered criteria</Typography>
          </Grid>
        ) : null}
    </Grid>
  )
}
