import { useState } from 'react'
import TextField from '@mui/material/TextField'
import { Auth } from 'aws-amplify'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import useNavigate from '../../helpers/useNavigation'
import { useStyles } from './style'
import { Alert } from '@mui/material'
import { useLocation } from 'react-router-dom'
import useInput from '../../utils/useInput'
import { useSelector } from 'react-redux'
import clientConfig from '../../reducers/clientConfig'
import { Forbidden } from '../index'

const SignUp = () => {
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({ error: false, message: '' })
  const navigate = useNavigate()
  const classes = useStyles()
  const { state } = useLocation()
  const { value: email, bind: bindEmail } = useInput('', { email: true })
  const { value: password, bind: bindPassword } = useInput('')
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput('')
  const { identityProviders } = useSelector(clientConfig.selectors.selectClientConfig)

  const handleSignUp = async e => {
    e.preventDefault()
    setLoading(true)

    if (password !== confirmPassword) {
      setInfo({ error: true, message: 'Passwords do not match', severity: 'error' })
      setLoading(false)
      return
    }
    try {
      await Auth.signUp({
        username: email,
        password: confirmPassword,
        attributes: {
          email
        }
      })
      setInfo({ error: false, message: 'Signup was successful' })
      navigate('/confirmation', { state: { ...state, email, sent: true } })
    } catch (error) {
      setInfo({ error: true, message: error.message })
    }
    setLoading(false)
  }
  const isCognitoProvider = identityProviders?.find(provider => provider.toLowerCase() === 'cognito') && identityProviders.length === 1

  return (
    !isCognitoProvider ?
      <Forbidden /> : (
        <div className={classes.container}>
          <img className={classes.logo} src='/img/logo.png' alt='Resilience Engine Logo' />
          <form
            className={classes.form}
            onSubmit={handleSignUp}
          >
            <h1 style={{ fontSize: '22px', fontWeight: 800 }}>
              {' '}
              New Account Registration
            </h1>
            <TextField className={classes.field} label='Email' {...bindEmail} type='email' />
            <TextField className={classes.field} label='Password' type='password' {...bindPassword} />
            <TextField
              className={classes.field}
              label='Confirm Password'
              type='password'
              {...bindConfirmPassword}
            />
            {info.message.length > 0 && <Alert severity={info.error ? 'error' : 'success'}>{info.message}</Alert>}
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              size='large'
              type='submit'
              disabled={loading}
            >
              {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
              Sign Up
            </Button>

            <Button onClick={() => { navigate('/signin', { state }) }}>Sign in</Button>
          </form>
        </div>)
  )
}
export default SignUp
