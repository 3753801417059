import React, { useEffect, useMemo } from 'react'
import { Grid, Paper, IconButton, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import {
  Empty,
  LowScoreMessage,
  ReButton,
  TeamReportsModal,
} from '../../components'
import { userSlice } from '../../reducers'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useStyles } from './style'
import NotesCard from './notes-card'
import { featureSelector } from '../../reducers/user/selectors'
import { useEventContext } from '../../contexts/event'
import Forbidden from '../403'
import { useDashboardContext } from '../../hooks'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import ability from '../../config/ability'

const Reports = () => {
  const classes = useStyles()
  const { dashboardData } = useDashboardContext()
  const user = useSelector(userSlice.selectors.selectUser)
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const isIndividualView = (selectedView && selectedView.type === 'individual') || !selectedView
  const features = useSelector(featureSelector)
  const { getUser } = useEventContext()
  const [teamData, setTeamData] = React.useState([])
  const [teamReports, setTeamReports] = React.useState()
  const [userClick, setUserClick] = React.useState(false)
  const [loading, setLoading] = React.useState()
  const [openModal, setOpenModal] = React.useState(false)

  const userResults = useMemo(() => {
    const result = []
    if (dashboardData?.getAllEvaluationResults) {
      dashboardData?.getAllEvaluationResults.forEach((report) => {result.push(report)})}
    if (dashboardData.getAllIndicatorResults) {
      dashboardData?.getAllIndicatorResults.forEach((report) => {result.push(report)})
    }
    return result
  }, [dashboardData])

  let hasLowScoreReport = false
  const getUserResults = result => {
    hasLowScoreReport = !hasLowScoreReport ? ['breakdown', 'fragmentation'].includes(result.modelResult.levelKey) : hasLowScoreReport
    return {
      score: result.score,
      level: result.modelResult.level,
      link: result.pdf,
      date: moment(result.pdfDate).format(
        'DD-MM-YYYY'
      ),
      showPdf: !['breakdown', 'fragmentation'].includes(result.modelResult.levelKey)
    }
  }

  const hasReports = userResults && userResults.length
  const reports = hasReports
    ? userResults.map(result => getUserResults(result))
    : false
  const headers = [
    { title: 'Date', key: 'date' },
    { title: 'Score', key: 'score' },
    { title: 'Level', key: 'level' },
    { title: 'Download', key: 'link' }
  ]

  const [fetchInfoForTeamReports] = useCorvusEventList([{
    eventName: 'Team Info For Team Reports Was Requested',
    onSuccess: (res) => {
      setTeamData(res)
    }
  }])

useEventsOnViewLoad(() => {
  if (!!user.teams && !!user.teams) {
    fetchInfoForTeamReports.trigger({ tenantKey: user.clientId, teamId: user.teams })
  }
}, [fetchInfoForTeamReports, user])

const [teamReportData] = useCorvusEventList([{
  eventName: 'getDataForTeamReport',
  onSuccess: (res) => {
    setTeamReports(res)
  }
}])

const fetchTeamResults = async (teamId, type) => {
  setUserClick(true)
  teamReportData.trigger({type: type, view: selectedView, team: teamId})
}

const clearCommonStates = () => {
  setTeamReports()
  setUserClick(false)
  setLoading(false)
  setOpenModal(false)
}

useEffect(() => {
  if (userClick === true){
    if(!!teamReports && (teamReports?.users || teamReports?.evaluationTotalUsers) < 4){
      window.alert(`Less than 4 responses. Unable to generate report.`)
      clearCommonStates()
    }
    if(!!teamReports?.users && teamReports?.users >= 4 && !!teamReports?.link){
    setOpenModal(true)
    }
  }
}, [teamData, userClick, teamReports])

const isOrgAdminOrSuperAdmin = ability.can('view', 'OrgView') || ability.can('view', 'superAdminView')

  return (
    !isIndividualView || ((!features.RESILIENCE_EVALUATION && !features.RESILIENCE_INDICATOR && !features.RESILIENCE_TRACKER) && !getUser.isWorking)
      ? <Forbidden />
      : !getUser.isWorking
        ? <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      My Reports and Notes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={1}>
                    <Typography className={classes.subtitle}>
                      In this section of the Resilience Dynamic Dashboard® you will be able to explore all of your self-assessment reports, for either the Resilience Dynamic® Questionnaire or Indicator. The number of reports shown below indicate how many times you have filled in your self-assessment. You can sort through your versions of reports by date, and download any version that you wish to view by using the download button on the right-side of the table.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container paddingTop={3} paddingBottom={2}>
                  {headers.map((header, key) => (
                    <Grid item key={key} xs={3} display='flex' alignItems='center'>
                      <Typography className={classes.subtitle}>{header.title}</Typography>
                    </Grid>
                  ))}
                  {hasLowScoreReport && (<Grid item> <LowScoreMessage /> </Grid>)}
                  {!!hasReports && reports.map((report, idx) => (
                    <Grid container justifyContent='space-between' key={idx}>
                      {report.showPdf && headers.map((header, key) => (
                        <Grid item key={key} xs={3} display='flex' alignItems='center'>
                          {header.key === 'link'
                            ? (
                              <IconButton
                                title='Download PDF'
                                aria-label='Download PDF'
                                className={classes.resultBtn}
                                onClick={() => window.open(`${report[header.key]}`)}
                                size='large'
                              >
                                <GetAppIcon fontSize='small' />
                              </IconButton>)
                            : (<Typography className={classes.result}> {report[header.key]} </Typography>
                            )}
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                  {!hasReports && (
                    <Grid item xs={12}>
                      <Empty />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <NotesCard />
            { !!isOrgAdminOrSuperAdmin &&
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={classes.title}>
                        Team Reports
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container paddingTop={3} paddingBottom={2}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650, maxWidth: 800 }} size='small' aria-label='team-reports'>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.reportsTableHeader}>Group Name</TableCell>
                            <TableCell className={classes.reportsTableHeader}>Generate reports</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!teamData && teamData.map((team, idx) => (
                            <TableRow className={classes.reportsTableBody} key={idx}>
                              <TableCell scope='row'>{team.name}</TableCell>
                              <TableCell scope='row'>
                                {features?.RESILIENCE_EVALUATION && <ReButton
                                  name={loading === `${idx}-questionnaire` ? <Typography><CircularProgress size={20} /> Loading</Typography> : 'Questionnaire Report'}
                                  disabled={loading}
                                  onClick={() => {
                                    setLoading(`${idx}-questionnaire`)
                                    fetchTeamResults(team.teamId, 'resilience')
                                  }}
                                />}
                                {features?.RESILIENCE_INDICATOR && <ReButton
                                  name={loading === `${idx}-indicator` ? <Typography><CircularProgress size={20} /> Loading</Typography> : 'Indicator Report'}
                                  disabled={loading}
                                  onClick={() => {
                                    setLoading(`${idx}-indicator`)
                                    fetchTeamResults(team.teamId, 'indicator')
                                  }}
                                />}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TeamReportsModal openModal={openModal} teamReports={teamReports} clearCommonStates={clearCommonStates} setOpenModal={setOpenModal} />
                  </Grid>
                </Paper>
              </Grid>}
          </Grid>
        </div> : <>Loading...</>
  )
}

export default Reports
