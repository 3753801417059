import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: `${theme.spacing(6)} ${theme.spacing(3)}`
  },
  text: {
    padding: `${theme.spacing(3)} ${theme.spacing(3)}  ${theme.spacing(4)}  ${theme.spacing(3)} `,
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#52575C'
  },
  title: {
    fontWeight: '400',
    color: '#25282B',
    paddingBottom: `${theme.spacing(4)}`
  },
  rowSpacing: {
    paddingTop: `${theme.spacing(4)}`
  }
}))
