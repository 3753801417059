const datas1 = [
  {
    name: 'Demand',
    color: '#6f61a6',
    values: [
      { date: new Date('2021-02-14'), score: '3' },
      { date: new Date('2021-02-15'), score: '5' },
      { date: new Date('2021-02-16'), score: '6' },
      { date: new Date('2021-02-17'), score: '1' },
      { date: new Date('2021-02-18'), score: '2' },
      { date: new Date('2021-02-19'), score: '3' },
      { date: new Date('2021-02-20'), score: '4' }
    ]
  },
  {
    name: 'Capacity',
    color: '#b168a1',
    values: [
      { date: new Date('2021-02-14'), score: '5' },
      { date: new Date('2021-02-15'), score: '6' },
      { date: new Date('2021-02-16'), score: '4' },
      { date: new Date('2021-02-17'), score: '2' },
      { date: new Date('2021-02-18'), score: '6' },
      { date: new Date('2021-02-19'), score: '1' },
      { date: new Date('2021-02-20'), score: '2' }
    ]
  },
  {
    name: 'Energy',
    color: '#5EBED0',
    values: [
      { date: new Date('2021-02-14'), score: '6' },
      { date: new Date('2021-02-15'), score: '4' },
      { date: new Date('2021-02-16'), score: '5' },
      { date: new Date('2021-02-17'), score: '3' },
      { date: new Date('2021-02-18'), score: '1' },
      { date: new Date('2021-02-19'), score: '2' },
      { date: new Date('2021-02-20'), score: '3' }
    ]
  }
]

const legend1 = [
  { name: 'demand', color: '#6f61a6' },
  { name: 'capacity', color: '#b168a1' },
  { name: 'energy', color: '#5EBED0' }
]

const datas2 = [
  {
    name: 'Sleep',
    color: '#5B4A99',
    values: [
      { date: new Date('2021-02-14'), score: '3' },
      { date: new Date('2021-02-15'), score: '5' },
      { date: new Date('2021-02-16'), score: '6' },
      { date: new Date('2021-02-17'), score: '1' },
      { date: new Date('2021-02-18'), score: '2' },
      { date: new Date('2021-02-19'), score: '3' },
      { date: new Date('2021-02-20'), score: '4' }
    ]
  },
  {
    name: 'Stress',
    color: '#BF347C',
    values: [
      { date: new Date('2021-02-14'), score: '5' },
      { date: new Date('2021-02-15'), score: '6' },
      { date: new Date('2021-02-16'), score: '4' },
      { date: new Date('2021-02-17'), score: '2' },
      { date: new Date('2021-02-18'), score: '6' },
      { date: new Date('2021-02-19'), score: '1' },
      { date: new Date('2021-02-20'), score: '2' }
    ]
  },
  {
    name: 'Wellbeing',
    color: '#6CADC0',
    values: [
      { date: new Date('2021-02-14'), score: '6' },
      { date: new Date('2021-02-15'), score: '4' },
      { date: new Date('2021-02-16'), score: '5' },
      { date: new Date('2021-02-17'), score: '3' },
      { date: new Date('2021-02-18'), score: '1' },
      { date: new Date('2021-02-19'), score: '2' },
      { date: new Date('2021-02-20'), score: '3' }
    ]
  },
  {
    name: 'Contentedness',
    color: '#868B92',
    values: [
      { date: new Date('2021-02-14'), score: '' },
      { date: new Date('2021-02-15'), score: '' },
      { date: new Date('2021-02-16'), score: '3' },
      { date: new Date('2021-02-17'), score: '3' },
      { date: new Date('2021-02-18'), score: '4' },
      { date: new Date('2021-02-19'), score: '1' },
      { date: new Date('2021-02-20'), score: '' }
    ]
  }
]

const legend2 = [
  { name: 'sleep', color: '#5B4A99' },
  { name: 'stress', color: '#BF347C' },
  { name: 'wellbeing', color: '#6CADC0' },
  { name: 'contentedness', color: '#868B92' }
]

const tips = [
  {
    id: 'resilience-overview',
    title: 'Resilience Dynamic Dashboard® Overview',
    type: 'video',
    feature: 'none',
    image: 'https://static.resilienceengine.live/img/overview.png',
    url: 'https://static.resilienceengine.live/videos/overview-new.mp4',
    content: {
      title: 'Dashboard Overview',
      text: [
        'This video will introduce you to the Resilience Dynamic Dashboard® and explore the three main components: The Resilience Dynamic® Questionnaire; the Resilience Tracker; and the Resilience Toolkit. Discover how to use each component & explore how you can make some easy shifts so that you optimise your resilience to feel and perform better!'
      ]
    }
  },
  {
    id: 'user-guide',
    title: 'User Guide',
    type: 'download',
    feature: 'none',
    image: 'https://static.resilienceengine.live/img/user-guide.svg',
    url: 'https://static.resilienceengine.live/pdf/user-guide.pdf',
    content: {
      title: 'User Guide',
      text: [
        'This document will guide you through each feature of the Resilience Dynamic Dashboard®. It covers the main questions for each feature: What is it? Why might you use it? How do you use it? Where to find it on the dashboard? As well as providing some helpful tips.'
      ]
    }
  },
  {
    id: 'why-track',
    title: 'Why track?',
    type: 'video',
    feature: 'RESILIENCE_TRACKER',
    image: 'https://static.resilienceengine.live/img/why.png',
    url: 'https://static.resilienceengine.live/videos/why.mp4',
    content: {
      title: 'Why track your resilience?',
      text: [
        'This video aims to introduce you to the idea of “why track?” and how to track well. You will explore 2 different users with real-world data, and how these users might analyse their own tracking data to see the benefits of tracking and improving their resilience. For any questions, please get in touch with your Resilience Engine coach, or email via helpdesk@resilienceengine.freshservice.com.'
      ]
    }
  },
  {
    id: 'top-tips-tracking',
    title: 'Top Tips to Make Tracking Work for You',
    type: 'download',
    feature: 'RESILIENCE_TRACKER',
    image: 'https://static.resilienceengine.live/img/Top-Tips-Tracking.png',
    url: 'https://static.resilienceengine.live/pdf/Top-Tips-To-Make-Tracking-Work.pdf',
    content: {
      title: 'Top Tips to Make Tracking Work for You',
      text: [
        'Once you have completed your Resilience Dynamic® Questionnaire, and had your debrief with your Resilience Engine coach, the next step may be to begin tracking your resilience, day-to-day or weekly. This document explores some helpful tips that will make tracking work for you. It covers why track, deciding your benefits of tracking; choosing when and how to track; exploring your patterns to find your key actions; and building your tracking habit. For any questions, please get in touch with your Resilience Engine coach, or email via helpdesk@resilienceengine.freshservice.com.'
      ]
    }
  },
  {
    id: 'resilience-toolkit-overview',
    title: 'Resilience Toolkit Overview',
    type: 'video',
    feature: 'RESILIENCE_TOOLKIT',
    image: 'https://static.resilienceengine.live/img/toolkit-overview.svg',
    url: 'https://static.resilienceengine.live/videos/Toolkit-Overview.mp4',
    content: {
      title: 'Resilience Toolkit Overview',
      text: [
        'This short video will introduce you to the Resilience Toolkit and demonstrate how it can be used to help boost your resilience, daily. '
      ]
    }
  },
  {
    id: 'evaluation-overview',
    title: 'Resilience Dynamic® Questionnaire Overview ',
    type: 'video',
    feature: 'RESILIENCE_EVALUATION',
    image: 'https://static.resilienceengine.live/img/onboarding-self-assessment.svg',
    url: 'https://static.resilienceengine.live/videos/RDQ-Overview.mp4',
    content: {
      title: 'Resilience Dynamic® Questionnaire Overview ',
      text: [
        'This short video will introduce you to the Resilience Dynamic® Questionnaire. Exploring what the questions are, how to answer the questionnaire, and finally how to access and read your report.'
      ]
    }
  }
]

export { datas1, legend1, legend2, datas2, tips }
