import React, { useEffect, useCallback, useMemo } from 'react'
import { AppBar, Toolbar, Grid, Typography, IconButton } from '@mui/material'
import { NewTrackerDialog } from '..'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { userSlice, trackerSlice } from '../../reducers'
import { Auth } from 'aws-amplify'
import { useStyles } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { useOnboarding, useQuery } from '../../hooks'
import { HelpOutline } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import useNavigate from '../../helpers/useNavigation'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'

const AppToolbar = ({ fullWidth }) => {
  const classes = useStyles()
  const context = useConnectionContext()
  const { openOnHome, openOnFeature, mapSteps } = useOnboarding()
  const location = useLocation()
  const navigate = useNavigate()
  const pathname = useMemo(() => location.pathname.split('/').filter(Boolean).pop(), [location])

  const shouldShowHelpButton = useMemo(() => {
    const paths = Object.values(mapSteps).filter(step => step.path).map(step => step.path)
    paths.push('dashboard')
    return paths.includes(pathname)
  }, [mapSteps, pathname])

  const onHelpPress = useCallback(() => {
    if (pathname === 'dashboard') {
      openOnHome()
    } else {
      openOnFeature(pathname)
    }
  }, [pathname, openOnHome, openOnFeature])

  const query = useQuery()
  const dispatcher = useDispatch()
  const toolBarStyle = fullWidth ? classes.fullAppToolbar : classes.appToolbar
  const trackerParam = query.get('tracker')
  const selectedView = useSelector(userSlice.selectors.selectedView)

  const user = useSelector(userSlice.selectors.selectUser)

  const handleLogout = async () => {
    try {
      await Auth.signOut()
      context.reloadConnection()

      dispatcher(userSlice.actions.resetUser())
      navigate('/')
    } catch (e) {
      console.log('Signout error: ', e)
    }
  }

  const openTracker = useCallback(() => {
    dispatcher(trackerSlice.actions.setShowTracker(true))
  }, [dispatcher])

  useEffect(() => {
    if (user && user.sub && trackerParam) {
      openTracker()
    }
  }, [user, trackerParam, openTracker])

  const renderProfilePic = () => {
    if (user?.profilePic) {
      return (
        <div className={classes.profileImageWrapper}>
          <img className={classes.profileImage} src={user.profilePic.url} alt='profile' />
        </div>
      )
    }
    return <AccountCircleIcon fontSize='inherit' />
  }

  return (
    <AppBar
      position='fixed'
      className={toolBarStyle}
    >
      <Toolbar>
          <Grid container alignItems='center'>
            <Grid item xs={12}>
              <Grid container alignItems='center'>
                <Grid item xs={12} md={6}>
                  {selectedView && selectedView.type === 'org' ? (
                    <Typography className={classes.header} variant='h5'> Resilience Dynamic Dashboard® Organisational</Typography>
                  ) : (
                    <Typography className={classes.header} variant='h5'>
                      Resilience Dynamic Dashboard®<span className={classes.invisible}>{process.env.REACT_APP_BUILD_ID}</span>
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container justifyContent='flex-end'>
                    {
                      shouldShowHelpButton &&
                        <Grid item xs={6} md={3} className={classes.toolbarOpts}>
                          <IconButton color='primary' onClick={onHelpPress}><HelpOutline /></IconButton>
                        </Grid>
                    }
                    <Grid item>
                      <Grid container justifyContent='center' alignItems='center' direction='row'>
                        {user && (
                          <Grid item>
                            <IconButton aria-label='Profile' className={classes.noHover} size='large'>
                              {renderProfilePic()}
                              <Typography variant='body2' className={classes.marginBorderRight}> {user.name} </Typography>
                            </IconButton>
                          </Grid>
                        )}
                        <Grid item>
                          <IconButton
                            aria-label='Logout'
                            className={classes.noHover}
                            onClick={handleLogout}
                            size='large'
                          >
                            <Typography variant='body2'> Logout </Typography>
                            <PowerSettingsNewIcon fontSize='inherit' />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <NewTrackerDialog />
      </Toolbar>
    </AppBar>
  )
}

export default AppToolbar
