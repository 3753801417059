import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  homePaper: {
    borderRadius: '10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)'
  },
  toolPathwayGrid: {
    height: theme.spacing(33),
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2)
  },
  dotContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  dotWrapper: {
    height: '20px',
    width: '20px'
  },
  dot: {
    backgroundColor: '#C4C4C4',
    height: '6px',
    width: '6px',
    borderRadius: '100%'
  },
  selected: {
    backgroundColor: '#465270'
  }
}))
