import React, { useState } from 'react'
import {
  IconButton, Dialog, DialogActions, DialogContent, DialogContentText, Menu, MenuItem, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, DialogTitle, Button, Chip, Skeleton
} from '@mui/material'
import { useStyles } from './styles'
import { CustomOverlay, Empty, ReButton } from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Add, MoreVert } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useAlert } from '../../hooks'
import { clientSlice } from '../../reducers'
import { useSelector } from 'react-redux'
import ability from '../../config/ability'
import { Forbidden } from '../index'

const OrganisationsHome = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { state } = useLocation()
  const clients = useSelector(clientSlice.selectors.selectClientList)
  const [orgListWasRequested] = useCorvusEventList([clientSlice.eventBus.orgListWasRequested])

  useEventsOnViewLoad(() => {
    if (state?.disableInitialFetch) return
    orgListWasRequested.trigger()
  }, [orgListWasRequested, state])

  const handleStatusChangeSuccess = () => {
    orgListWasRequested.trigger()
  }

  const isSuperAdmin = ability.can('view', 'superAdminView')

  return (
    !isSuperAdmin ? <Forbidden />
      : <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant='h3'>Organisations</Typography>
          <ReButton name='New Organisation' rounded action={() => navigate('new')} dataTestId='new_org_btn' icon={<Add />} />
        </div>
        <CustomOverlay text='' active={orgListWasRequested.isWorking} spinner={false}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size='small' aria-label='organisations' className={classes.tableWrapper}>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Admin</TableCell>
                  <TableCell>Licenses</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map(client => {
                  if (client.loading) {
                    return (
                      <TableRow key={client.id}>
                        <TableCell colspan='5'>
                          <Skeleton height={60} width='100%' />
                        </TableCell>
                      </TableRow>
                    )
                  }
                  return (
                    <TableRow key={client.id}>
                      <TableCell scope='row'>{client.name}</TableCell>
                      <TableCell scope='row'>
                        {client.orgAdminEmail.map(eachEmail => (
                          <Typography key={eachEmail.adminEmail}>
                            {eachEmail.adminEmail}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell scope='row'>
                        {client.licenses?.map(eachLicense => (
                          <Typography key={eachLicense.name}>
                            {eachLicense.name}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell scope='row'>{client.created}</TableCell>
                      <TableCell scope='row'>{client.isActive
                        ? <Chip label='Active' sx={{ backgroundColor: '#ECFBF6', color: '#03B575' }} />
                        : <Chip label='Inactive' />}
                      </TableCell>
                      <MoreOptionsCell row={client} onStatusChangeSuccess={handleStatusChangeSuccess} />
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {clients.length === 0 && !orgListWasRequested.isWorking && <Empty message='There are no organisations to display' />}
        </CustomOverlay>
      </div>
  )
}

const MoreOptionsCell = ({ row, onStatusChangeSuccess }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [statusModalOpen, setStatusModalOpen] = useState(false)
  const { showSuccessAlert, showErrorAlert } = useAlert()

  const [changeOrgStatus] = useCorvusEventList([{
    eventName: 'Organisation Status Has Changed',
    onSuccess: () => {
      showSuccessAlert('Sucess!', 'the organisation status has changed!')
      onStatusChangeSuccess()
      handleCloseStatusModal()
    },
    onError: (error) => {
      showErrorAlert('Error!', 'An unexpected error occurred')
      console.log('status error', error)
    }
  }])

  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleEdit = () => {
    navigate(`edit/${row.id}`)
  }

  const handleStatus = () => {
    changeOrgStatus.trigger({ orgId: row.id })
  }

  const handleOpenStatusModal = () => {
    setStatusModalOpen(true)
  }

  const handleCloseStatusModal = () => {
    handleClose()
    setStatusModalOpen(false)
  }

  return (
    <>
      <TableCell>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleOpenStatusModal}>{row.isActive === true ? 'Disable' : 'Enable'}</MenuItem>
        </Menu>
      </TableCell>
      <Dialog
        open={statusModalOpen}
        onClose={handleCloseStatusModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Change Organisation Status?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {row.isActive === true
              ? 'Do you really want to disable this organisation?'
              : 'Do you really want to enable this organisation?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={changeOrgStatus.isWorking} onClick={handleCloseStatusModal}>Cancel</Button>
          <LoadingButton loading={changeOrgStatus.isWorking} loadingIndicator='loading...' onClick={handleStatus} autoFocus>
            {row.isActive === true
              ? 'Disable'
              : 'Enable'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default OrganisationsHome
