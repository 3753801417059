import { Grid, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardTracker, FilterBar, TrackerChart, TrackerFilter } from '../../../components'
import { getResilienceAverage } from '../../../helpers/utils'
import useDashboardContext from '../../../hooks/useDashboardContext'
import { trackerSlice, userSlice } from '../../../reducers'
import { useStyles } from './combinationStyles'

const Tracker = () => {
  const [resilienceGraph, setResilienceGraph] = useState([])
  const [wellbeingGraph, setWellbeingGraph] = useState([])
  const { filters, selectedView, isOrgProfile } = useDashboardContext()
  const [resilienceAverages, setResilienceAverages] = useState([])
  const classes = useStyles()

  const dashboardData = useSelector(userSlice.selectors.dashboardData)
  const dispatcher = useDispatch()
  const setShowTracker = value => {
    dispatcher(trackerSlice.actions.setShowTracker(value))
  }

  useEffect(() => {
    if (dashboardData && dashboardData.trackerAnswers.length > 0) {
      const { resilience, wellbeing } = dashboardData.trackerAnswers.reduce((acc, curr) => {
        if (['demand', 'capacity', 'energy'].includes(curr.question.name)) {
          acc.resilience.push(curr)
        } else {
          acc.wellbeing.push(curr)
        }
        return acc
      }, { resilience: [], wellbeing: [] })
      setResilienceGraph(resilience)
      setWellbeingGraph(wellbeing)
      setResilienceAverages(getResilienceAverage(dashboardData.trackerAnswers, filters.date, selectedView?.type))
    } else {
      setResilienceGraph([])
      setWellbeingGraph([])
      setResilienceAverages([])
    }
  }, [dashboardData, filters, selectedView])

  return (
    <Grid container marginY={1}>
      <Paper elevation={2} className={classes.homePaper} style={{ flexDirection: 'column' }}>
        <Grid container padding={2}>
          <Grid item xs={12} display='flex' justifyContent='flex-start'>
            <TrackerFilter />
            {!isOrgProfile && <FilterBar />}
          </Grid>
          <Grid item xs={6}>
            <DashboardTracker
              title='Resilience Tracker'
              description='Measures your resilience values'
              emptyTitle='Resilience Tracker'
              emptyDescription='The questionnaire takes on average 5 minutes'
              values={resilienceGraph}
              startHandler={() => setShowTracker(true)}
            >
              {resilienceGraph.length > 0 && (
                <TrackerChart
                  chartData={resilienceGraph}
                  type='resilience'
                  users={dashboardData.trackerTotalUsers}
                  isOrgProfile={isOrgProfile}
                />
              )}
            </DashboardTracker>
          </Grid>
          <Grid item xs={6}>
            <DashboardTracker
              title='Wellbeing Drivers'
              description='Measures your wellbeing drivers'
              values={wellbeingGraph}
            >
              {wellbeingGraph.length > 0 && (
                <TrackerChart
                  chartData={wellbeingGraph}
                  type='wellbeing'
                  users={dashboardData.trackerTotalUsers}
                  isOrgProfile={isOrgProfile}
                />
              )}
            </DashboardTracker>
          </Grid>
        </Grid>
        <Grid item xs={12} padding={2}>
          <DashboardTracker
            title='Resilience Average'
            description='Measures your resilience averages across a range of areas'
            horizontal
            values={resilienceAverages}
          />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Tracker
