import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(3),
    minWidth: '210px'
  },
  popover: {
    backgroundColor: 'grey',
    padding: '16px',
    color: 'white'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    paddingBottom: theme.spacing(4)
  },
  emptyTextColor: {
    color: '#A0A4A8'
  },
  emptyTitle: {
    color: theme.palette.secondary.dark,
    fontWeight: 'regular',
    marginBottom: '16px'
  },
  emptyDescription: {
    marginBottom: '16px'
  },
  emptyDataText: {
    color: '#A0a4a8',
    marginLeft: '16px'
  },
  popoverIcon: {
    margin: '7px 12px',
    height: 19,
    width: 19,
    fill: '#CD1975'
  },
  collapseIcon: {
    fill: '#A0A4A8'
  },
  expandIcon: {
    fill: theme.palette.primary.dark
  },
  showHideIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  orgContent: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  content: {
    width: '100%',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center'
  },
  verticalContent: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  horizontalContent: {
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center'
  },
  empty: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  orgButton: {
    textTransform: 'none',
    float: 'right'
  },
  peopleIcon: {
    color: '#CACCCF'
  }
}))
