import { Grid, Skeleton, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Bar, BarChart, CartesianGrid, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { DashboardAverage, Empty } from '..'
import { getAreas } from '../../helpers/utils'
import useDashboardContext from '../../hooks/useDashboardContext'

const chartHeight = 250

export const initialOptions = [
  { key: 'psychological_safety', name: 'PSYCHOLOGICAL SAFETY', color: '#BF347C' },
  { key: 'workload_stress', name: 'WORKLOAD & STRESS', color: '#6CADC0' },
  { key: 'trust', name: 'TRUST', color: '#868B92' },
  { key: 'engagement', name: 'ENGAGEMENT', color: '#5B4A99' }
]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  )
}

const DriversBarChart = ({ parsedAreas, loading, empty }) => {
  const {isOrgProfile} = useDashboardContext()
  if (loading) {
    return <Grid display='flex' width='100%' justifyContent='center'><Skeleton variant='rectangular' width='50%' height={chartHeight} /></Grid>
  }
  if (empty) {
    return <Empty message={!!isOrgProfile ? 'No data to display. This is because not enough people have provided results for the filtered criteria' : 'There is no data to display'}/>
  }

  return (
    <ResponsiveContainer width='50%' height={chartHeight + 30}>
      <BarChart
        height={chartHeight + 30}
        data={parsedAreas}
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <Tooltip />
        <CartesianGrid vertical={false} />
        <XAxis dataKey='name' tick={false} tickSize={0} />
        <YAxis ticks={[1, 2, 3, 4, 5, 6]} tickCount={0} />
        {initialOptions.map(({ key, color }) => (
          <Bar key={key} dataKey={key} stackId='a' barSize={30} fill={color} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

const DriversPieChart = ({ parsedAreas, loading, empty }) => {
  if (loading) {
    return <Skeleton variant='rectangular' width='50%' height={chartHeight} />
  }
  if (empty) {
    return null
  }

  return (
    <ResponsiveContainer width='50%' height={chartHeight}>
      <PieChart height={chartHeight} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
        <Pie
          data={parsedAreas}
          cx='50%'
          cy='50%'
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={100}
          fill='#8884d8'
          dataKey='value'
        >
          {parsedAreas.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={initialOptions.find(i => i.key === entry.key).color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

const DriverScores = ({ parsedAreas, loading, empty }) => {
  if (loading) {
    return (
      <Grid display='flex' flexWrap='wrap' width='50%' justifyContent='space-between' padding={2}>
        <Skeleton variant='rectangular' width='45%' height={85} />
        <Skeleton variant='rectangular' width='45%' height={85} />
        <Skeleton variant='rectangular' width='45%' height={85} />
        <Skeleton variant='rectangular' width='45%' height={85} />
      </Grid>
    )
  }
  if (empty) {
    return null
  }

  return (
    <Grid container width='50%'>
      {parsedAreas.map(({ key, name, value }) => (
        <Grid key={key} xs={5} margin={1}>
          <DashboardAverage title={name} description='' type='score' value={value} />
        </Grid>
      ))}
    </Grid>
  )
}

export const RenderDrivers = ({ chartOptions }) => {
  const { viewLoading, dashboardData } = useDashboardContext()
  const areas = useMemo(() => getAreas(dashboardData.evaluationReportResult, true), [dashboardData])
  const parsedAreas = useMemo(() => {
    if (!areas) return []
    const possibleFilters = chartOptions.filter(i => i.isSelected)
    let result = Object.entries(areas)
    if (possibleFilters.length) {
      result = result.filter(([k]) => possibleFilters.find(j => j.key === k))
    }
    return result.map(([k, v]) => ({
      key: k,
      name: v.title,
      value: Number.parseFloat(v.score),
      [k]: v.score
    }))
  }, [areas, chartOptions])

  const shouldShowEmpty = useMemo(() => {
    if (!areas || !Object.keys(areas).length || !dashboardData?.evaluationReportResult) return true
    return false
  }, [areas, dashboardData])

  return (
    <Grid>
      <Grid display='flex' padding={2} gap={2}>
        <Grid width={shouldShowEmpty ? '100%' : '50%'} display='flex'>
          <DriversBarChart parsedAreas={parsedAreas} loading={viewLoading} empty={shouldShowEmpty} />
          <DriversPieChart parsedAreas={parsedAreas} loading={viewLoading} empty={shouldShowEmpty} />
        </Grid>
        <DriverScores parsedAreas={parsedAreas} loading={viewLoading} empty={shouldShowEmpty} />
      </Grid>
      {dashboardData?.evaluationTotalUsers && !viewLoading
        ? (
          <Grid display='flex' justifyContent='flex-end' paddingRight={5}>
            <Typography>{dashboardData?.evaluationTotalUsers} results for the filtered criteria</Typography>
          </Grid>
        ) : null}
    </Grid>
  )
}
