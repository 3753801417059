import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
    backgroundColor: '#FFFFFF'
  },
  modalTitle: {
    fontWeight: '600',
    color: theme.palette.secondary.dark,
    paddingBottom: `${theme.spacing(2)}`,
    paddingTop: `${theme.spacing(2)}`,
  }
}))
