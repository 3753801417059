import React from 'react'
import { Grid, Paper, Tooltip, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './style'
import { Empty } from '..'
import useResolvePathFromBaseURL from '../../helpers/useResolvePathFromBaseURL'
import ReButton from '../re-button'

const ResilienceEvaluationCard = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { pathname: surveyPath } = useResolvePathFromBaseURL('survey')

  return (
    <div className={classes.container}>
      <Grid item xs={4} className='onboarding-self-assessment evaluation'>
        <Paper elevation={2} className={classes.paper}>
          <Grid item xs={12} display='flex' justifyContent='space-around' alignItems='center'>
            <Grid item xs={5}>
              <img src='/img/evaluation-card-image.svg' alt='Person looking at indicators' />
            </Grid>
            <Grid item xs={5} display='flex' flexDirection='column' alignItems='left' gap={2}>
              <Typography variant='span'>Resilience Dynamic® Questionnaire
                <Tooltip title='The Resilience Dynamic® Questionnaire, an in-depth self-assessment of your resilience level.'>
                  <InfoOutlinedIcon className={classes.informationIcon} />
                </Tooltip>
              </Typography>
              <ReButton action={() => navigate(surveyPath)} className={classes.startNowButton} color='secondary' variant='contained' name='Start now' />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper elevation={2} className={classes.paper}>
          <Grid item xs={12} display='flex' flexDirection='column'>
            <Typography variant='h6' color='text.grey' fontWeight='bold'>Resilience Dynamic® Questionnaire Results</Typography>
            <Empty />
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}

export default ResilienceEvaluationCard
