import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  firstChildMenu: {
    marginTop: theme.spacing(3)
  },
  menuItem: {
    display: 'flex',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF'
    },
    '& > span ': {
      fontSize: '13px'
    }
  },
  child: {
    paddingLeft: theme.spacing(3),
    '& > span ': {
      fontSize: '13px'
    }
  },
  menuItemSelected: {
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF !important'
  },
  menuItemDisabled: {
    color: '#DBDDE0',
    cursor: 'not-allowed'
  },
  childLabel: {
    fontSize: '11px',
    color: '#465270'
  },
  childNamePrimary: {
    fontSize: '12px',
    color: '#465270'
  },
  childNameSecondary: {
    fontSize: '10px',
    color: '#465270'
  }

}))
