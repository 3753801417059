import * as colors from '@mui/material/colors'

// This is the base palette that will be used to build the theme.
// `palette` param option will either override or add values to
// the palette property of theme object that is inject for the entire application.

// Add new properties as needed and all application components will have the
// new proprity available in the `theme.palette` object

// For more information about each property and what it means:
// https://material-ui.com/customization/palette/

export default {
  primary: {
    main: 'rgba(205, 25, 117, 0.8)',
    light: 'rgba(205, 25, 117, 0.3)',
    dark: '#CD1975',
    cicoBlue: '#23BBFF'
  },
  secondary: {
    light: 'rgba(70, 82, 112, 0.3)',
    main: 'rgba(70, 82, 112, 0.9)',
    dark: '#465270'
  },
  error: {
    light: '#FF7E77',
    main: '#FF6760',
    dark: '#E74F48'
  },
  warning: {
    light: '#FCDF98',
    main: '#FAC032',
    dark: '#EFAD0A'
  },
  neutral: '#F6F8FB',
  info: {
    light: colors.blue[300],
    main: colors.blue[500],
    dark: colors.blue[700]
  },
  success: {
    light: '#34EEAE',
    main: '#16D090',
    dark: '#03B575'
  },
  button: {
    default: 'rgba(70, 82, 112, 1)',
    link: '#696871',
    color: colors.common.white,
    border: '#ECE9F1'
  },
  type: 'light',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  // The colors used to style the text.
  text: {
    // The most important text.
    primary: '#25282B',
    // Secondary text.
    secondary: 'rgba(0, 0, 0, 0.54)',
    // Disabled text have even lower visual prominence.
    disabled: 'rgba(0, 0, 0, 0.38)',
    // Text hints.
    hint: 'rgba(0, 0, 0, 0.38)',
    // Custom color
    customColor: '#76ff03',
    greyDark: '#52575C',
    lightBlack: '#11263C',
    grey: '#A0A4A8'
  },
  // The color used to divide different elements.
  divider: 'rgba(0, 0, 0, 0.12)',
  // The background colors used to style the surfaces.
  // Consistency between these values is important.
  background: {
    paper: colors.common.white,
    default: colors.common.white
  },
  // The colors used to style the action elements.
  action: {
    // The color of an active action like an icon button.
    active: 'rgba(0, 0, 0, 0.54)',
    // The color of an hovered action.
    hover: 'rgba(205,25,117,0.700)',
    hoverOpacity: 0.04,
    // The color of a selected action.
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    // The color of a disabled action.
    disabled: 'rgba(0, 0, 0, 0.26)',
    // The background color of a disabled action.
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12
  }
}
