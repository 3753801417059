import React from 'react'
import { Grid, Typography, Link, Divider } from '@mui/material'
import { ReButton } from '../../components'
import { useStyles } from './style'

const ModalQuestionnaire = ({ title, subtitle, text, nextAction, nextLabel, dontShowAction, skipAction }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      direction='column'
      rowSpacing={3}
      className={classes.root}
    >
      <Grid item xs={12}>
        <Typography variant='h2' className={classes.title}>
          {title}
        </Typography>
        <Typography variant='h4' className={classes.text}>
          {subtitle}
        </Typography>
        <Typography variant='h4' className={classes.text}>
          {text}
        </Typography>
        <Divider variant='middle' />
      </Grid>
      <Grid item xs={12} className={classes.rowSpacing}>
        <ReButton name={nextLabel} dataTestId='next_step_modal_btn' action={nextAction} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.footerLink} variant='subtitle2' color='textPrimary'>
          <Link onClick={skipAction}>Skip for now</Link> and remind me later
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ModalQuestionnaire
