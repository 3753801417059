import React, { useEffect, useState } from 'react'
import { Grid, Paper, Typography, CircularProgress, Dialog, Skeleton } from '@mui/material'
import { useNavigate } from 'react-router'
import { useStyles } from './style'
import { useSelector } from 'react-redux'
import { userSlice } from '../../reducers'
import ToolPathwayItem from '../tool-pathway-item'
import { CloseOutlined } from '@mui/icons-material'

const YourTrackModal = ({ open, handleClose }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const userRead = useSelector(userSlice.selectors.selectUserRead)
  const user = useSelector(userSlice.selectors.selectUser)
  const pathwayType = user?.pathwayType?.name || 'General'

  const userPathwayMapping = {
    General: {
      title: 'General Pathway',
      img: 'General.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          This is the general tool pathway, for when your tracking data has been consistent both in amount of tracking, and the actual data that you are entering. This is an opportunity to explore the Resilience Toolkit in more depth, discovering some of the theory behind resilience and being inspired by some of the stories from within the toolkit.
        </Typography>
    },
    'No Tracking': {
      title: 'No Tracking Pathway',
      img: 'No-Tracking.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          Hey, we have noticed you haven’t been tracking recently. Not sure how or why it’s worth it?
          <br />
          Tracking your resilience and the drivers of wellbeing that most affect it, will help you surface exactly what’s going on with how well you are managing the demands day to day. If you don’t pay attention to the detail, you can fool yourself into false thinking, or indeed just maintain the status quo which may not be great.
          <br />
          If you’re not sure how to track well, look at the TIPS section
          <span
            style={{ color: '#0645AD', cursor: 'pointer' }}
            onClick={() => navigate('tips', {
              state: {
                title: 'Why track?',
                type: 'video',
                image: 'https://static.resilienceengine.live/img/why.png',
                url: 'https://static.resilienceengine.live/videos/why.mp4',
                content: {
                  title: 'Why track your resilience?',
                  text: [
                    'This video aims to introduce you to the idea of “why track?” and how to track well. You will explore 2 different users with real-world data, and how these users might analyse their own tracking data to see the benefits of tracking and improving their resilience. For any questions, please get in touch with your Resilience Engine coach, or email via helpdesk@resilienceengine.freshservice.com.'
                  ]
                }
              }
            })}
          >
            here
          </span>.
          <br />
          If it’s about what actions you might take, check out the TOOLS in your recommended TOOL PATHWAY.
        </Typography>
    },
    'Demand > Capacity': {
      title: 'Demand > Capacity Pathway',
      img: 'Demand-Capacity.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          Are you aware that you are consistently trading on your wellbeing to meet your resilience demand? If you keep doing this, your resilience levels will become under threat.
          <br />
          In the previous 4 weeks of your Resilience Tracker data, the levels of your Resilience Demand have exceeded your Resilience Capacity. Things might feel busy, busy, busy so often, that you don’t know what else to do other than to just keep going.But burnout comes from trading on your energy and wellbeing over time. It’s not that you’re not resourceful, your Resilience River just runs dry.
          <br />
          You need to pay attention to this trend now. Look at the recommended tools in your tool pathway to discover what you might do to change things up.
        </Typography>
    },
    'Wellbeing Long Term': {
      title: 'Wellbeing Long Term Pathway',
      img: 'Wellbeing-Long-Term.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          Watch out, your wellbeing is in danger. The warning bells are ringing, so please act.
          <br />
          In the previous 4 weeks of your Resilience Tracker data, the levels of your wellbeing have been consistently below 3. Many factors can have an influence over your wellbeing, both in work and at home. Workload, stress, allowing yourself to get consistently busy, busy, busy without proper recovery time. You need to pay attention to this right now.
          <br />
          Check out this <span style={{ color: '#0645AD', cursor: 'pointer' }} onClick={() => navigate('tool-pathway')}>tool pathway</span> on what you can do to stop the rut.
        </Typography>
    },
    'Wellbeing Short Term': {
      title: 'Wellbeing Short Term Pathway',
      img: 'Wellbeing-Short-Term.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          Woah, what happened to your self-care this week? It went out the window at some point!
          <br />
          In this last week we noticed your wellbeing dropped below 3. We wonder, did you notice what was going on? Was it a bad sleep? Stress? Fractious relationships? Difficult in work?
          A sudden drop in wellbeing is a warning that you don’t have all your resilience habits going.
          Those with the highest resilience, even in a challenging moment or event, will always pay attention to self care. Otherwise your adaptability is under threat.
          <br />
          Check out this pathway to see how you might get those habits nailed so they serve you every day, no matter what’s going on.
        </Typography>
    },
    'Intermittent Tracking': {
      title: 'Intermittent Tracking Pathway',
      img: 'Intermittent-Tracking.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          Within the past 4 weeks you have missed at least 1 full week of tracking your resilience & wellbeing using the Resilience Tracker. If the cause of not tracking for that week was down to a holiday or time off from work, remember you can fill in your tracking data retrospectively, using the date feature at the top left of the Resilience Tracker. Your tool pathway shows a collection of tools that will help you discover why tracking your resilience is important, and what you can help do to boost your resilience day-to-day.
        </Typography>
    },
    'Not Clicking Through to Tools': {
      title: 'Not Clicking Through to Tools Pathway',
      img: 'Not-Clicking-Through-to-Tools.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          Over the past two weeks you have not been clicking through to any of the tools in your tool pathways. Maybe you’ve been too busy in work, did you know that busy, busy is one of the barriers to resilience? Your tool pathway shows a collection of tools that will help you discover what the Resilience Toolkit holds, and how you can help boost your resilience day-to-day.
        </Typography>
    },
    Energy: {
      title: 'Energy Pathway',
      img: 'Energy.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          Within the past week of tracking your resilience & wellbeing using the Resilience Tracker, your energy has been equal to, or less than 3 consistently. Many things can have an impact on your energy, not just from the workplace. Energy & resilience tend to follow the same trends. Try to identify what lowers your energy, as well as discovering what gives you energy can be so impactful in helping to boost your energy and resilience daily. This tool pathway focuses on tools that will help you to discover more about energy and how you can boost yours.
        </Typography>
    },
    REQuestionnaire: {
      title: 'General Pathway',
      img: 'General.svg',
      description:
        <Typography className={classes.toolpathwayDescription} variant='body2'>
          This is the general tool pathway, for when your tracking data has been consistent both in amount of tracking, and the actual data that you are entering. This is an opportunity to explore the Resilience Toolkit in more depth, discovering some of the theory behind resilience and being inspired by some of the stories from within the toolkit.
        </Typography>
    }
  }

  const getItemStatus = (id) => {
    if (userRead.find(i => i.sk === id.toString())) return 'read'
    else return 'pending'
  }

  useEffect(() => {
    if (user) { setLoading(false) }
  }, [user])

  if (loading) return <CircularProgress />
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xl'>
      <Paper elevation={2} className={classes.container}>
        <CloseOutlined onClick={handleClose} className={classes.closeButton} />
        <Grid container direction='column' alignItems='center' justifyContent='space-between' className={classes.leftContent}>
          <Grid item>
            <img width={160} height={160} src={`/img/pathway/${userPathwayMapping[pathwayType].img}`} alt='your-track' />
          </Grid>
          <Grid container direction='row' item alignItems='flex-end' justifyContent='center'>
            <Typography variant='h6' className={classes.toolpathwayTitle}>{userPathwayMapping[pathwayType].title}</Typography>
          </Grid>
          <Grid item>
            {userPathwayMapping[pathwayType].description}
          </Grid>
        </Grid>
        <Grid id='tool-pathway-playlist' item className={classes.rightContent}>
          {user && user.toolpathway
            ? <>
              <Typography variant='h6' className={classes.rightContentTitle}>Check out this pathway to see what you can do to get out of the rut.</Typography>
              <Grid container direction='column'>
                {user.toolpathway.filter(Boolean).map((c, i) =>
                  <ToolPathwayItem
                    disabled={loading}
                    key={c.id}
                    item={c}
                    selectContent={() => navigate('tool-pathway-content', { state: { id: c.id } })}
                    itemStatusForUser={getItemStatus(c.id)}
                    bottomTrail={i !== user.toolpathway.length - 1}
                  />
                )}
              </Grid>
            </> : <Skeleton height={600} variant='rectangular' />}
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default YourTrackModal
