import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Fade, Grid, IconButton, Typography } from '@mui/material'
import { useStyles } from './style'
import { ReButton } from '../..'
import PopperWrapper from '../popper-wrapper'
import { useOnboarding } from '../../../hooks'
import useNavigation from '../../../helpers/useNavigation'
import { Close } from '@mui/icons-material'

export default function SelfAssessmentModal({ cancelOnBoarding }) {
  const classes = useStyles()
  const [ref, setRef] = useState(null)
  const [loaded, setLoaded] = useState(null)
  const { features, openOnHome } = useOnboarding()
  const navigate = useNavigation()

  const isEvaluation = useMemo(() => features.find(f => f.featureId === 'RESILIENCE_EVALUATION'), [features])

  const goToSelfAssessment = useCallback(() => {
    let route = 'indicator'
    if (isEvaluation) route = 'survey'
    navigate(route, { state: { fromOnBoarding: true } })
  }, [isEvaluation, navigate])

  useEffect(() => {
    setTimeout(() => {
      setRef(document.querySelector('.onboarding-self-assessment'))
      setLoaded(true)
    }, 0)
  }, [])

  return (
    <PopperWrapper element={ref} placement='right'>
      <Fade in={loaded} timeout={400}>
        <Grid
          container
          justifyContent='center'
          direction='column'
          flexWrap='nowrap'
          className={classes.root}
        >
          <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
            <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{ fontSize: '30px' }} /></IconButton>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '280px' }}>
              <img
                src='https://static.resilienceengine.live/img/onboarding-self-assessment.svg'
                alt='Additional User Settings Congratulations'
              />
            </div>
            <Typography variant='h3' className={classes.title}>
              Resilience Dynamic® {isEvaluation ? 'Questionnaire' : 'Indicator'}
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={3}>
            <Typography component='p' variant='body3'>
              {isEvaluation
                ? 'First, complete the Resilience Dynamic® Questionnaire, an in-depth self-assessment of your resilience level which is followed up by a debriefing session with a resilience coach.'
                : 'First, complete the Resilience Dynamic® Indicator, a light-touch self-assessment of your resilience level.'
              }
            </Typography>
          </Grid>
          <Grid item display='flex' justifyContent='center' gap={9} marginX={6}>
            <ReButton fullWidth name='Complete the Self-Assessment' dataTestId='close_congratulations_modal' action={goToSelfAssessment} />
            <Button fullWidth className={classes.laterButton} color='secondary' variant='text' dataTestId='close_congratulations_modal' onClick={openOnHome}>
              I'll do it later
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </PopperWrapper>
  )
}
