import { Paper, Typography } from '@mui/material'
import { useState } from 'react'

import ChartLegend from '../tracker-chart/chart-legend'
import { RenderDrivers } from './charts'
import { useStyles } from './styles'

export const initialOptions = [
  { key: 'psychological_safety', name: 'PSYCHOLOGICAL SAFETY', color: '#BF347C' },
  { key: 'workload_stress', name: 'WORKLOAD & STRESS', color: '#6CADC0' },
  { key: 'trust', name: 'TRUST', color: '#868B92' },
  { key: 'engagement', name: 'ENGAGEMENT', color: '#5B4A99' }
]

const OrganisationalDriversCard = () => {
  const classes = useStyles()
  const [chartOptions, setChartOptions] = useState(initialOptions)

  return (
    <Paper elevation={2} className={classes.paper}>
      <Typography variant='h5'>Organisation Drivers</Typography>
      <ChartLegend chartOptions={chartOptions} setChartOptions={setChartOptions} />
      <RenderDrivers chartOptions={chartOptions} />
    </Paper>

  )
}

export default OrganisationalDriversCard
