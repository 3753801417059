import React from 'react'
import { Grid } from '@mui/material'
import { LoginStart, ReFooter } from '../../components'
import { useStyles } from './style'

const LoginBody = () => {
  const classes = useStyles()

  return (
    <Grid container justifyContent='flex-end' alignItems='center' className={classes.fullHeight}>
      <Grid item xs={12} md={6}>
        <LoginStart />
      </Grid>

      <Grid item xs={12} md={6}>
        <img src='/img/home-icon.svg' alt='Resilience Engine Home' className={classes.image} />
      </Grid>

      <Grid item xs={12} className={classes.footer}>
        <ReFooter />
      </Grid>
    </Grid>
  )
}

export default LoginBody
