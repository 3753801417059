import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Add, ArrowLeft, ArrowRight, Edit } from '@mui/icons-material'
import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import { CustomOverlay, ReButton, Empty } from '../../components'
import { useStyles } from './style'
import moment from 'moment'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import { userSlice } from '../../reducers'

const TeamHome = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const [localTeams, setLocalTeams] = React.useState([])
  const [lastKey, setLastKey] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(-1)

  const [teamListWasRequested] = useCorvusEventList([{
    eventName: 'Team List Was Requested',
    onSuccess: (result) => {
      setLocalTeams(current => current.concat([result.teams]))
      setLastKey(result.lastKey)
      setCurrentPage(current => current + 1)
    }
  }])

  useEventsOnViewLoad(() => {
    if (localTeams.length === 0 && selectedView) {
      teamListWasRequested.trigger({ tenantKey: selectedView.id })
    }
  }, [teamListWasRequested, selectedView])

  const getNextPage = () => {
    if (localTeams.length <= currentPage + 1) {
      teamListWasRequested.trigger({ from: lastKey })
    } else {
      setCurrentPage(current => current + 1)
    }
  }
  const getPreviousPage = () => {
    setCurrentPage(current => current - 1)
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant='h3'>Groups</Typography>
        <ReButton name='New group' rounded action={() => navigate('new')} dataTestId='new_team_btn' icon={<Add />} />
      </div>
      <CustomOverlay text='' active={teamListWasRequested.isWorking} spinner={false}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table' className={classes.tableWrapper}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>NAME</TableCell>
                <TableCell>CREATED</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPage !== -1 && localTeams[currentPage].map((row) => (
                <TableRow key={row.name}>
                  <TableCell scope='row'>{row.name}</TableCell>
                  <TableCell>{moment(row.createdAt).format('DD/MM/YYYY - HH:mm')}</TableCell>
                  <TableCell>
                    <IconButton>
                      <Edit onClick={() => navigate(`${encodeURIComponent(row.teamId)}`)} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {((currentPage !== -1 && localTeams[currentPage].length === 0) || !localTeams[0]) && <Empty message='There are no groups to display' />}
          <Box display='flex' alignItems='center' justifyContent='center'>
            <IconButton disabled={currentPage === 0 || teamListWasRequested.isWorking} onClick={getPreviousPage}><ArrowLeft /></IconButton>
            {teamListWasRequested.isWorking ? <CircularProgress size={15} color='secondary' /> : <Typography>{currentPage + 1}</Typography>}
            <IconButton disabled={(!lastKey && currentPage + 1 === localTeams.length) || teamListWasRequested.isWorking} onClick={getNextPage}><ArrowRight /></IconButton>
          </Box>
        </TableContainer>
      </CustomOverlay>
    </div>
  )
}

export default TeamHome
