import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { Provider } from 'react-redux'
import store from './reducers/store'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

const MissingTenant = () => {
  return (
    <div>
      You need to pass a tenant key
    </div>
  )
}

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MissingTenant />} />
          <Route path=':tenantKey/*' element={<App />} />
        </Routes>
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
