import { ButtonBase, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import CICOProgress from '../cicoProgress'
import { useStyles } from './style'

const CICOHeader = ({ title, progress, selectedStep, hasFastTrack, fastOrDepth, setFastOrDepth, backToSteps, loading }) => {
  const classes = useStyles()
  if (loading) return null
  return (
    <Grid container justifyContent='space-between' alignItems='center' marginBottom={1}>
      <Grid display='flex' flexDirection='column'>
        <Grid display='flex' alignItems='center' gap={2}>
          <Typography variant='h6' color='text.greyDark'>{title}</Typography>
          {hasFastTrack &&
            <ToggleButtonGroup
              value={fastOrDepth}
              exclusive
              onChange={(_e, newAlign) => newAlign && setFastOrDepth(newAlign)}
              aria-label='text alignment'
              className={classes.toggleButtonGroup}
            >
              <ToggleButton value='fast'>
                Fast Track
              </ToggleButton>
              <ToggleButton value='depth'>
                In Depth
              </ToggleButton>
            </ToggleButtonGroup>}
        </Grid>
        {selectedStep !== null && fastOrDepth === 'depth' &&
          <Grid marginTop={1}>
            <ButtonBase onClick={backToSteps}>
              <img src='/icons/left-arrow.svg' alt='left arrow' width={16} />
              <Typography marginLeft={1} variant='caption' fontSize={16} color='secondary'>Back to steps</Typography>
            </ButtonBase>
          </Grid>}
      </Grid>
      <Grid display='flex' alignItems='center' gap={2}>
        <Typography color='primary' variant='h6'>Your {title === 'Checkin Checkout' ? 'CICO' : ' Resilience Fundamentals'} Progress</Typography>
        <CICOProgress loading={false} size='medium' progress={progress} />
      </Grid>
    </Grid>
  )
}
export default CICOHeader
