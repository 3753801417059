import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(33),
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)'
  },
  informationIcon: {
    cursor: 'pointer',
    margin: '-4px 5px',
    width: 19,
    height: 19,
    fill: '#CD1975'
  },
  startNowButton: {
    width: theme.spacing(18)
  }
}))
