import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Box } from '@mui/system'
import { Dialog } from '@mui/material'
import { useStyles } from './style'
import { ModalIntro } from '..'
import { settingsSlice, trackerSlice, userSlice } from '../../reducers'
import { cacheImages } from '../../helpers/cacheImages'
import { TrackerHeader } from './tracker-header'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { TrackerStep } from './tracker-step'
import ModalSettings from './modal-settings'

const checkIfAnswered = (trackerSteps, trackerAnswers, type) => {
  const dailies = trackerSteps?.filter(step => step.type === type)
  const dailyQuestions = dailies?.reduce((acc, step) => [...acc, ...step.questions], [])
  const answered = dailyQuestions?.filter(daily => trackerAnswers?.find(answer => answer.question.id === daily.id))
  return answered.length === dailyQuestions.length
}

const NewTrackerDialog = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [getUserTrackerAnswerBus] = useCorvusEventList([userSlice.eventBus.getUserTrackerAnswerBus])

  const showTracker = useSelector(trackerSlice.selectors.selectShowTracker)
  const hasTriggeredOnOpen = useRef(false)

  const userSettings = useSelector(settingsSlice.selectors.selectUserSettings)
  const trackerSteps = useSelector(trackerSlice.selectors.selectTrackerSteps)
  const trackerAnswers = useSelector(userSlice.selectors.selectTrackerAnswers)
  const hasDoneDaily = useMemo(() => checkIfAnswered(trackerSteps, trackerAnswers, 'Daily'), [trackerSteps, trackerAnswers])
  const hasDoneWeekly = useMemo(() => checkIfAnswered(trackerSteps, trackerAnswers, 'Weekly'), [trackerSteps, trackerAnswers])

  const [currentStepIdx, setCurrentStepIdx] = useState(0)

  const setShowTracker = useCallback(value => {
    dispatch(trackerSlice.actions.setShowTracker(value))
  }, [dispatch])

  const steps = useMemo(() => {
    const trackerStepsOrdered = trackerSteps.slice().sort((a, b) => {
      if (a.type === 'Daily' && b.type === 'Weekly') return -1
      if (a.type === 'Weekly' && b.type === 'Daily') return 1
      if (['Daily', 'Weekly'].includes(a.type) && b.type === 'Notes') return -1
      if (['Daily', 'Weekly'].includes(b.type) && a.type === 'Notes') return 1
      return 0
    })
    const result = [{ id: 'intro' }, { id: 'settings' }]
    return result.concat(trackerStepsOrdered)
  }, [trackerSteps])

  const currentStep = useMemo(() => steps[currentStepIdx], [steps, currentStepIdx])

  const onDailyClick = useCallback(() => setCurrentStepIdx(steps.findIndex(step => step.type === 'Daily')), [steps])
  const onWeeklyClick = useCallback(() => setCurrentStepIdx(steps.findIndex(step => step.type === 'Weekly')), [steps])
  const onNoteClick = useCallback(() => setCurrentStepIdx(steps.findIndex(step => step.type === 'Notes')), [steps])

  useEffect(() => {
    cacheImages(['/img/tracker-modal.svg', '/img/tracker-settings.svg', ...trackerSteps.map(step => step.cover)])
  }, [trackerSteps])

  useEffect(() => {
    getUserTrackerAnswerBus.trigger({ start: moment().startOf('week'), end: moment().endOf('day') })
  }, [getUserTrackerAnswerBus])

  const handleNextButton = useCallback((overrideCurrent = null) => {
    let localCurrentIdx = overrideCurrent ?? currentStepIdx
    let localCurrent = steps[localCurrentIdx]
    if (['intro', 'settings'].includes(localCurrent.id)) {
      let nextStep = steps[localCurrentIdx + 1]
      const isNextWeeklyOrDaily = ['Weekly', 'Daily'].includes(nextStep?.type)
      if (isNextWeeklyOrDaily) {
        if (hasDoneDaily && hasDoneWeekly) {
          const nextStepIdx = steps.findIndex(step => step.type === 'Notes')
          setCurrentStepIdx(nextStepIdx)
          return
        }
        if (hasDoneDaily) {
          const nextStepIdx = steps.findIndex(step => step.type === 'Weekly')
          setCurrentStepIdx(nextStepIdx)
          return
        }
      }
    }
    if (localCurrent.type === 'Daily') {
      const isNextStepWeekly = steps[currentStepIdx + 1].type === 'Weekly'
      if (hasDoneWeekly && isNextStepWeekly) {
        const nextStepIdx = steps.findIndex(step => step.type === 'Notes')
        setCurrentStepIdx(nextStepIdx)
        return
      }
    }

    setCurrentStepIdx(s => ((overrideCurrent ?? s) + 1))
  }, [currentStepIdx, hasDoneDaily, hasDoneWeekly, steps])

  // on open
  useEffect(() => {
    if (showTracker && !hasTriggeredOnOpen.current) {
      hasTriggeredOnOpen.current = true
      if (userSettings?.trackerOnBoarding?.trackerWelcomeDisabled && userSettings?.trackerOnBoarding?.trackerInitialSettingsDisabled) {
        handleNextButton(steps.findIndex(step => step.id === 'settings'))
        return
      }
      if (userSettings?.trackerOnBoarding?.trackerWelcomeDisabled) {
        handleNextButton()
        return
      }
    }
  }, [showTracker, handleNextButton, currentStep, userSettings?.trackerOnBoarding, steps])

  // on close
  useEffect(() => {
    if (!showTracker) {
      hasTriggeredOnOpen.current = false
      setTimeout(() => setCurrentStepIdx(0), 500)
    }
  }, [showTracker])

  const renderStep = useCallback(() => {
    switch (currentStep.id) {
      case 'intro': return (
        <ModalIntro
          nextAction={handleNextButton}
          skipAction={() => setShowTracker(false)}
        />
      )
      case 'settings': return (
        <ModalSettings
          nextAction={handleNextButton}
          skipAction={() => setShowTracker(false)}
        />
      )
      default: return (
        <>
          <TrackerHeader step={currentStep} steps={steps} hasDoneDaily={hasDoneDaily} hasDoneWeekly={hasDoneWeekly} {...{ onDailyClick, onWeeklyClick, onNoteClick }} />
          {steps.map((step, idx) => step === currentStep && <TrackerStep key={idx} step={step} handleNextButton={handleNextButton} />)}
        </>
      )
    }
  }, [steps, currentStep, setShowTracker, hasDoneDaily, hasDoneWeekly, onDailyClick, onWeeklyClick, onNoteClick, handleNextButton])

  return (
    <Dialog
      open={showTracker}
      classes={{ paperWidthSm: classes.root }}
      onClose={() => setShowTracker(false)}
    >
      <Box display='flex' flexDirection='column' width='calc(1000px - 48px)' height='100%'>
        {renderStep()}
      </Box>
    </Dialog>
  )
}

export default NewTrackerDialog
